.menu_wrapper {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff3d00;
}
.menu_wrapper .menu_container {
  width: calc(100% - 20vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu_wrapper .menu_container .logo img {
  width: 80px;
}/*# sourceMappingURL=style.css.map */