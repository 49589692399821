.home_wrapper {
  min-height: 100vh;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
}
.home_wrapper img {
  border: 1px solid #ff3d00;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}
.home_wrapper .banner_container {
  min-height: 20vh;
  height: auto;
  padding: 50px 0 30px 0;
  width: calc(100% - 20vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.home_wrapper .banner_container .left_banner {
  text-align: left;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.home_wrapper .banner_container .left_banner h1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  font-size: 3.825vw;
  font-style: normal;
  line-height: 4.3vw;
  letter-spacing: -0.1875vw;
}
.home_wrapper .banner_container .left_banner p {
  width: 80%;
}
.home_wrapper .banner_container .left_banner h6 {
  color: #ff3d00;
}
.home_wrapper .banner_container .right_banner {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_wrapper .banner_container .right_banner img {
  border: none;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}
.home_wrapper .banner_container h1,
.home_wrapper .banner_container h6,
.home_wrapper .banner_container p {
  color: #000;
  text-align: left;
}
.home_wrapper h6 {
  flex-shrink: 0;
  color: #ff3d00;
  font-size: 1.5625vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7708333333vw;
  margin-bottom: 1.5625vw;
}
.home_wrapper p {
  color: #1d1d1d;
  text-align: center;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2vw;
}
.home_wrapper .home_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 20vw);
}
.home_wrapper .home_container .block_container {
  text-align: left;
}
.home_wrapper .home_container .block_container .topic {
  color: #ff3d00;
  font-size: 1.5625vw;
  font-style: normal;
  font-weight: 600;
  margin-top: 1.5625vw;
}
.home_wrapper .home_container .block_container h4 {
  font-size: 2rem;
  margin-bottom: 50px;
}
.home_wrapper .home_container .block_container p {
  text-align: justify;
  line-height: 1.8229166667vw;
}
.home_wrapper .home_container .action_button {
  font-size: 1.3vw;
  margin: 50px 0;
  font-weight: 600;
}
.home_wrapper .home_container .action_button a {
  color: #fff;
  text-decoration: none;
}
.home_wrapper .home_container .action_button strong {
  color: #fff;
  background: #ff3d00;
  border-radius: 50px;
  padding: 10px 20px;
  transition: all ease 0.3s;
  cursor: pointer;
}
.home_wrapper .home_container .action_button strong:hover {
  background: #c42e00;
}

@media (min-width: 0px) and (max-width: 800px) {
  .home_wrapper .banner_container .left_banner {
    width: 100%;
  }
  .home_wrapper .banner_container .left_banner h1 {
    font-size: 6.825vw;
    line-height: 8.3vw;
    letter-spacing: -0.1875vw;
  }
  .home_wrapper .banner_container .left_banner p {
    width: 100%;
  }
  .home_wrapper .banner_container .left_banner h6 {
    color: #ff3d00;
    font-size: 4vw;
  }
  .home_wrapper .banner_container .right_banner {
    display: none;
  }
  .home_wrapper h6 {
    font-size: 3.5625vw;
    line-height: 1.7708333333vw;
    margin-bottom: 4.5625vw;
  }
  .home_wrapper p {
    font-size: 2.8vw;
    line-height: 4.2vw;
  }
  .home_wrapper .home_container .block_container {
    text-align: left;
  }
  .home_wrapper .home_container .block_container .topic {
    font-size: 3.5625vw;
    margin-top: 2.5625vw;
    margin-bottom: 10px;
  }
  .home_wrapper .home_container .block_container h4 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .home_wrapper .home_container .block_container p {
    text-align: justify;
    line-height: 4.8229166667vw;
  }
}/*# sourceMappingURL=style.css.map */