.footer_wrapper {
  height: 100px;
  width: 100%;
  background: #ff3d00;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer_container {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .rights{
        font-size: 10px;
    }
  }
}
