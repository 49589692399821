.menu_wrapper{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff3d00;
    .menu_container{
        width: calc(100% - 20vw);
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .logo{
            img{
                width: 80px;

            }
        }
    }
}