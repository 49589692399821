@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.App {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #ffffff;
  font-family: 'Inter', sans-serif;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}